import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { AiOutlineClose } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { getAuth } from "firebase/auth";
import { authError } from "../../utils/errors/authError";
import * as Toast from "@radix-ui/react-toast";
import Link from "next/link";
import cookie from "js-cookie";
import { useAuth } from "../../auth/AuthProvider";
import { CgProfile } from "react-icons/cg";
import { rtdb } from "../../scripts/firebase";
import { onValue, orderByChild, query, ref } from "firebase/database";
import Image from "next/image";

const SideNavDialog = ({
  openNav,
  setOpenNav,
  open,
  setOpen,
  active,
  setActive,
  actionMode,
  setActionMode,
  destination,
  setDestination,
  successCallBack,
  setSuccessCallBack,
}) => {
  //  console.log(successCallBack)
  const [openToast, setOpenToast] = useState(false);

  const auth = getAuth();

  const router = useRouter();
  //   const user = auth.currentUser;
  const contextAuth = useAuth();
  const user = contextAuth.user;
  const loadingUser = contextAuth.loading;
  const claims = contextAuth.claims;

  const [allUnread, setAllUnread] = useState(0);

  const [error, setError] = useState(null);

  const isAuth = cookie.get("fast_auth");

  const [loadingMessages, setLoadingMessages] = useState(true);

  const messageRef = query(
    ref(rtdb, "Users/" + user?.uid + "/PropertyMessages"),
    orderByChild("lastMessage/time")
  );

  useEffect(() => {
    const reff = onValue(
      messageRef,
      async (snapshot) => {
        let c = 0;
        snapshot.forEach(function (child) {
          c = c + child.val().unreadCountUser;
        });
        setAllUnread(c);
        setLoadingMessages(false);
      },
      {
        onlyOnce: false,
      }
    );
    return () => reff();
  }, [loadingMessages, user]);

  return (
    <>
      <Dialog.Root
        open={openNav}
        onOpenChange={(open) => {
          // console.log(open)
          if (!open) {
            setOpenNav(open);
          }
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>

        <AnimatePresence>
          {/* {openNav ? ( */}
          <Dialog.Portal>
            <Dialog.Overlay>
              <motion.div
                key="overlay"
                initial={{ x: 0 }}
                animate={{ x: 0.5 }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.05,
                  },
                }}
                className=" fixed  inset-0 z-30  flex h-screen items-center justify-center   bg-black opacity-50"
              ></motion.div>
            </Dialog.Overlay>

            <Toast.Provider swipeDirection="right">
              <Toast.Root
                duration={2800}
                className=" flex w-[80vw] flex-row justify-between space-x-0 rounded-lg border border-red-100  bg-red-100 px-6 py-4 shadow-lg lg:w-auto"
                open={openToast}
                onOpenChange={() => {
                  setOpenToast(false);
                  setError(null);
                }}
              >
                <AnimatePresence>
                  {openNav && (
                    <motion.div
                      initial={{ opacity: 0, x: 0, scale: 0.7 }}
                      animate={{ opacity: 1, x: 20, scale: 1 }}
                      exit={{
                        opacity: 0,
                        x: 0,
                        scale: 0.7,
                        transition: { duration: 0.6 },
                      }}
                      className="flex flex-row"
                    >
                      <div className=" flex flex-col space-y-0">
                        <Toast.Title className="  mb-0 text-base font-semibold text-red-600 ">
                          {authError(error?.errorCode).title}
                        </Toast.Title>
                        <Toast.Description asChild>
                          <span className=" text-sm text-red-600">
                            {authError(error?.errorCode).message}
                          </span>
                        </Toast.Description>
                      </div>
                      <Toast.Action altText="Close">
                        <span
                          onClick={() => {
                            setOpenToast(false);
                          }}
                          className=" invisible ml-8 rounded-md border  border-red-200 bg-transparent px-4 py-1 text-xs font-semibold text-black "
                        >
                          Close
                        </span>
                      </Toast.Action>
                      <Toast.Close />
                    </motion.div>
                  )}
                </AnimatePresence>
              </Toast.Root>

              <Toast.Viewport className=" fixed bottom-0 right-0 z-40 m-0 mb-5 mr-12 flex max-w-screen-lg flex-col gap-10 lg:mr-10" />
            </Toast.Provider>
            <AnimatePresence>
              {openNav && (
                <Dialog.Content
                  className=""
                  forceMount
                  // onOpenAutoFocus={(e) => {
                  //   e.preventDefault();
                  // }}
                >
                  <motion.div
                    key="modal"
                    initial={{ x: 120 }}
                    animate={{ x: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 40,
                      duration: 0.8,
                    }}
                    exit={{
                      opacity: 0,
                      x: 120,
                      transition: {
                        duration: 5.05,
                      },
                    }}
                    className="fixed right-0 top-0 z-30 h-screen w-[80vw] bg-white  lg:hidden"
                  >
                    <Dialog.Title className="  flex flex-row items-center justify-between border px-5 py-3 text-center text-2xl  text-black">
                      <span className="select-none font-eastman font-medium text-ula-primary">
                        ula
                      </span>
                      <Dialog.Close asChild>
                        <span className=" flex h-6 w-6 flex-shrink-0  cursor-pointer items-center justify-center rounded-full text-sm hover:bg-gray-200">
                          <AiOutlineClose className=" h-4 w-4 text-gray-500" />
                        </span>
                      </Dialog.Close>
                    </Dialog.Title>
                    <div className="  flex h-full flex-col space-y-3 bg-white px-5 py-3">
                      {!user && !isAuth && !loadingUser ? (
                        <button
                          onClick={() => {
                            setOpen(!open);
                            setOpenNav(!openNav);
                          }}
                          className=" w-full rounded-md bg-ula-primary py-3 font-medium text-gray-100"
                        >
                          Register/Login
                        </button>
                      ) : null}

                      {user ? (
                        <div className=" flex flex-col space-y-1 pt-2  font-medium text-gray-700">
                          <h2 className=" font-semibold text-gray-800">
                            Account
                          </h2>
                          <div className=" flex flex-col space-y-2">
                            <div className=" flex flex-row items-center space-x-3 py-2">
                              <div
                                className={
                                  " relative flex h-8 w-8  cursor-pointer  items-center justify-center rounded-full bg-blue-100 lg:h-10 lg:w-10" +
                                  (allUnread > 0 && claims?.user
                                    ? "  border-2 border-pink-500"
                                    : " border border-gray-300")
                                }
                              >
                                {user?.photoURL &&
                                user?.photoURL !== "https://default" ? (
                                  <Image
                                    src={user?.photoURL}
                                    className=" h-full w-full rounded-full  object-cover"
                                    priority
                                    layout="fill"
                                    alt=""
                                  />
                                ) : (
                                  <CgProfile className=" h-4 w-4 text-ula-primary lg:h-5 lg:w-5" />
                                )}
                              </div>
                              <div className=" flex flex-col items-start space-y-0 text-sm text-gray-800">
                                <span className=" cursor-default select-none text-base font-medium">
                                  {user?.displayName}
                                </span>
                                <div className=" flex flex-col items-start space-y-1">
                                  <span className=" select-none text-sm text-gray-600">
                                    {user?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {claims?.manager ? (
                              <div
                                onClick={() => {
                                  router.push("/lister/dashboard").then(() => {
                                    setOpenNav(!openNav);
                                  });
                                }}
                                className=" cursor-pointer px-0 py-0 text-gray-800 underline hover:bg-gray-200"
                              >
                                Dashboard
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* {claims?.user ? (
                              <div className="  flex w-full  pb-2 text-sm">
                                <span
                                  onClick={() => {
                                    router.push("/account/rewards").then(() => {
                                      setOpenNav(!openNav);
                                      //   setPopOpen(false);
                                    });
                                  }}
                                  className=" w-full cursor-pointer rounded-full bg-[#80FF80]  px-5 py-2 text-center font-medium text-zinc-900 "
                                >
                                  Invite agent and get Kshs 2000
                                </span>
                              </div>
                            ) : null} */}
                            {claims?.user ? (
                              <div
                                onClick={() => {
                                  router.push("/account/favorites").then(() => {
                                    setOpenNav(!openNav);
                                  });
                                }}
                                className=" cursor-pointer px-0 py-0 text-gray-800 hover:bg-gray-200"
                              >
                                Saved Homes
                              </div>
                            ) : null}
                            {claims?.user ? (
                              <div
                                onClick={() => {
                                  router.push("/account/messages").then(() => {
                                    setOpenNav(!openNav);
                                  });
                                }}
                                className=" flex cursor-pointer flex-row items-center justify-between py-0 pl-0  text-gray-800 hover:bg-gray-200"
                              >
                                <span>Messages</span>
                                {claims?.user && allUnread > 0 ? (
                                  <span className=" flex h-5 w-5 items-center justify-center rounded-full bg-pink-500 text-xs font-semibold text-white">
                                    {allUnread}
                                  </span>
                                ) : null}
                              </div>
                            ) : null}

                            <div
                              onClick={() => {
                                auth.signOut().then(() => {
                                  setOpenNav(!openNav);
                                  router.push("/");
                                });
                              }}
                              className=" cursor-pointer px-0 py-0  text-gray-800 hover:bg-gray-200"
                            >
                              Sign out
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className=" flex flex-col space-y-1 pt-2  font-medium text-gray-700">
                        <h2 className=" font-semibold text-gray-800">
                          Get app
                        </h2>
                        <div className=" flex flex-col space-y-1">
                          <Link href="/ula-app" passHref>
                            <a
                              onClick={() => {
                                setOpenNav(!openNav);
                              }}
                            >
                              Ula africa mobile app
                            </a>
                          </Link>
                        </div>
                      </div>

                      <div className=" flex flex-col space-y-1 pt-2  font-medium text-gray-700">
                        <h2 className=" font-semibold text-gray-800">Agents</h2>
                        <div className=" flex flex-col space-y-1">
                          <Link href="/real-estate-agency-near-me" passHref>
                            <a
                              onClick={() => {
                                setOpenNav(!openNav);
                              }}
                            >
                              Find Agent
                            </a>
                          </Link>
                          <Link href="/hodari" passHref>
                            <a
                              onClick={() => {
                                setOpenNav(!openNav);
                              }}
                            >
                              Join as Agent
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div className=" flex flex-col space-y-1 pt-2  font-medium text-gray-700">
                        <h2 className=" font-semibold text-gray-800">Tools</h2>
                        <div className=" flex flex-col space-y-1">
                          <Link
                            href="/calculators/rent-budget-calculator"
                            passHref
                          >
                            <a
                              onClick={() => {
                                setOpenNav(!openNav);
                              }}
                            >
                              Rent Budget Calculator
                            </a>
                          </Link>
                          <Link href="/calculators/mortgage-calculator">
                            <a
                              onClick={() => {
                                setOpenNav(!openNav);
                              }}
                            >
                              Mortgage Calculator
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div className=" flex flex-col space-y-1 pt-2  font-medium text-gray-700">
                        <h2 className="  font-semibold text-gray-800">
                          Resources
                        </h2>
                        <div className=" flex flex-col space-y-2">
                          <Link href="/blog" passHref>
                            <a
                              onClick={() => {
                                setOpenNav(!openNav);
                              }}
                            >
                              Blog
                            </a>
                          </Link>
                        </div>
                      </div>
                      {/* {!user ? (
                        <div className="  flex w-full  pb-2 text-sm">
                          <span
                            onClick={() => {
                              router.push("/referral").then(() => {
                                setOpenNav(!openNav);
                                //   setPopOpen(false);
                              });
                            }}
                            className=" w-full cursor-pointer rounded-full bg-[#80FF80]  px-5 py-2 text-center font-medium text-zinc-900 "
                          >
                            Invite agent and get Kshs 2000
                          </span>
                        </div>
                      ) : null} */}
                    </div>
                  </motion.div>
                </Dialog.Content>
              )}
            </AnimatePresence>
          </Dialog.Portal>
          {/* // ) : null} */}
        </AnimatePresence>
      </Dialog.Root>
      <></>
    </>
  );
};

export default SideNavDialog;
