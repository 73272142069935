
export const authError = (errorCode, errorMessage) => {
  if (errorCode === "auth/user-not-found") {
    return {
      title: "Sorry, we couldn't find an account with that email",
      message: "Create an account with the email",
    };
  }
  if (errorCode === "auth/too-many-requests") {
    return {
      title: "Too many requests",
      message: "Wait a couple of minutes and then try again",
    };
  }
  if (errorCode === "auth/email-already-in-use") {
    return {
      title: "Account already exists",
      message: "Looks like you have an account. Login instead?",
    };
  } else if (errorCode === "auth/weak-password") {
    return {
      title: "Weak Password",
      message: "Try a longer password with a mix of letters and numbers",
    };
  } else if (errorCode === "auth/popup-closed-by-user") {
    return {
      title: "Pop up closed",
      message: "The signin process was cancelled",
    };
  } else if (errorCode === "auth/wrong-password") {
    return {
      title: "Wrong Password",
      message: "Reset your password or use Sign in with Google",
    };
  } else if (errorCode === "auth/network-request-failed") {
    return {
      title: "No internet connection",
      message: "Check your internet connection",
    };
  } else if (
    errorCode === "auth/internal-error" ||
    errorCode === "functions/internal" ||
    errorCode === "appCheck/fetch-network-error"
  ) {
    return {
      title: "Error Occurred",
      message:
        "Check if you are connected to the internet. Contact support if error persists ",
    };
  } else if (errorCode === "Account already exists as User") {
    return {
      title: "Account already exists",
      message: "Use a different email account to list a property",
    };
  } else if (errorCode === "auth/account-exists-with-different-credential") {
    return {
      title: "This credential already exists",
      message: "Use a different phone number",
    };
  } else if (errorCode === "auth/code-expired") {
    return {
      title: "OTP code has expired",
      message: "Click 'resend' to get a new one",
    };
  } else if (errorCode === "auth/invalid-verification-code") {
    return {
      title: "Code does not match",
      message: "Enter the exact code sent to your phone",
    };
  } else if (errorCode === "auth/account-already-exists-as-user") {
    return {
      title: "Account already exists",
      message: "Use a different email account to list a property",
    };
  } else {
    return { title: "Error Occurred", message: errorCode };
  }
};
